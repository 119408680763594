import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GuardedComponent } from './guarded/guarded.component';
import { MsalGuard } from '@azure/msal-angular';
import { IdeasListComponent } from './ideas-list/ideas-list.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { JoinComponent } from './join/join.component';
import { IdeaDetailsComponent } from './idea-details/idea-details.component';
import { AuthGuardService } from './guards/auth.guard';
import { FablabComponent } from './fablab/fablab.component';
import { TalentComponent } from './talent/talent.component';
import { AvailabilityCalendarComponent } from './availability-calendar/availability-calendar.component';
import { AvailabilityListComponent } from './availability-list/availability-list.component';

/**
 * MSAL Angular can protect routes in your application
 * using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */
const routes: Routes = [
  {
    path: 'guarded',
    component: GuardedComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'ideas',
    component: IdeasListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'fablab',
    component: FablabComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'talent',
    component: TalentComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'idea-details/:type/:code',
    component: IdeaDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'availability/:screen/:type/:code',
    component: AvailabilityCalendarComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'availability-table/:screen/:type/:code/:location/:dateString',
    component: AvailabilityListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'thank-you',
    component: ThankYouComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'join',
    component: JoinComponent,
    canActivate: [AuthGuardService],
  },
  {
    // Needed for hash routing
    path: 'error',
    component: HomeComponent,
  },
  {
    // Needed for hash routing
    path: 'state',
    component: HomeComponent,
  },
  {
    // Needed for hash routing
    path: 'code',
    component: HomeComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabled' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
