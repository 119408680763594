import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-confimation-dialog',
  templateUrl: './confimation-dialog.component.html',
  styleUrls: ['./confimation-dialog.component.css']
})
export class ConfimationDialogComponent implements OnInit {

  title: string;
  message: string;
  type:string;
  icon:string;
  screen:string;
  executionMode:string;
  dialogFormGroup:FormGroup;
  messages:string[] = [];
  constructor(public dialogRef: MatDialogRef<ConfimationDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.messages = this.message.split("<br/>");
    this.type = data.type;
    this.icon = data.icon ? data.icon : "info";
    this.screen = data.screen? data.screen: "";
    this.executionMode = data.executionMode? data.executionMode: "";
  }

  ngOnInit() {
    this.dialogFormGroup = this.fb.group({
      ModalitaEsecuzione: [ this.executionMode ],
      NoteProponente: [ "", [ Validators.maxLength(4000) ] ],
    });
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.dialogFormGroup.value);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
export class ConfirmDialogModel {

  constructor(public title: string, public message: string, public type: string, public icon?:string, public screen?:string, public executionMode?:string ) {
  }
}
