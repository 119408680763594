<div *ngIf="type == 'reserve-confirmation' || type == 'cancel-confirmation'">
    <h1 mat-dialog-title><i class="fa fa-{{icon}}" aria-hidden="true"></i> {{title}}</h1>
    <mat-divider></mat-divider> 
    <div mat-dialog-content>
        <p>{{message}}</p>
        
        <form [formGroup]="dialogFormGroup" *ngIf="type == 'reserve-confirmation'">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group" *ngIf="screen == 'operator' && executionMode == 'Presenza'">
                        <label for="ModalitaEsecuzione">Scegli modalità</label>
                        <select class="form-control" id="ModalitaEsecuzione" formControlName="ModalitaEsecuzione">
                            <option value="Presenza" selected>Presenza</option>
                            <option value="Video">Video call</option>
                        </select>
                        <div class="small">
                            Questo colloquio può essere svolto in presenza presso la nostra sede. In alternativa, se lo preferisci, puoi selezionare la modalità Video call e svolgere il colloquio con il nostro operatore mediante la piattaforma gratuita <a target="_blank" href="https://www.microsoft.com/it-it/microsoft-teams/log-in">Microsoft Teams</a>.
                            <br />
                            In ognuno dei due casi, una volta confermato l'appuntamento, riceverai una e-mail con tutti i dettagli utili.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="NoteProponente">Note (facoltative, max 4.000 caratteri)</label>
                        <textarea class="form-control" id="NoteProponente" formControlName="NoteProponente" rows="5"></textarea>
                        <div class="ng-invalid" *ngIf="dialogFormGroup.controls['NoteProponente'].errors?.maxlength">
                            Il testo inserito è troppo lungo, inserire un testo più corto (max 4.000 caratteri)
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="footer">
        <button class="btn btn-primary btn-yes" (click)="onConfirm()" [disabled]="!dialogFormGroup.valid" [ngClass]="!dialogFormGroup.valid ? 'btn-disabled' : ''">Confermo</button> 
        <button class="btn btn-default btn-no"  (click)="onDismiss()">No</button>
    </div>
</div>

<div *ngIf="type != 'reserve-confirmation' && type != 'cancel-confirmation'">
    <h1 mat-dialog-title class="{{type}}"><i class="fa fa-{{icon}}"></i> {{title}}</h1>
    <mat-divider></mat-divider> 
    <div mat-dialog-content>
        <p *ngFor="let msg of messages;">{{msg}}</p>
    </div>
    <div mat-dialog-actions class="footer">
        <button class="btn btn-primary btn-yes" (click)="onConfirm()">OK</button>
    </div>
</div>