import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  AuthenticationResult,
  InteractionStatus,
} from '@azure/msal-browser';
import { AppGlobalsService } from '../services/app-globals.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ToastService } from '../services/toast.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit, OnChanges {
  private readonly _destroying$ = new Subject<void>();
  displayedColumns: string[] = ['claim', 'value'];
  loginDisplay = false;
  userEmail: any;
  joinUrl = '';
  ideaCode = '';
  ideaUserEmail = '';
  emailLinkSubject = '';
  emailLinkBody = '';
  @Input() codiceIdea = '';
  @Input() ideaFrom = '';

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public _appGlobals: AppGlobalsService,
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getClaims(
          this.authService.instance.getActiveAccount()?.idTokenClaims
        );
        this._appGlobals.claims =
          this.authService.instance.getActiveAccount()?.idTokenClaims;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.codiceIdea) {
      this.ideaCode = this.codiceIdea;
      this.joinUrl = `${window.location.origin}/join`;
      this.ideaUserEmail = this._appGlobals.claims.emails[ 0 ];
      this.emailLinkSubject = 'Aderisci al mio progetto/idea';
      this.emailLinkSubject += !!this.ideaFrom ? ' ' + this.ideaFrom : '';
      this.emailLinkBody =
        'Ciao,%0D%0Aecco le informazioni per aderire al mio progetto/idea:%0D%0A%0D%0A- collegati a questo indirizzo ' +
        this.joinUrl +
        '%0D%0A- registra la tua utenza o accedi con le tue credenziali (se ne sei già in possesso)%0D%0A- accedi alla pagina ADERISCI AD UNA IDEA%0D%0A- inserisci il CODICE IDEA: ' +
        this.ideaCode +
        "%0D%0A- inserisci l'indirizzo email del proponente della idea: " +
        this.ideaUserEmail +
        '%0D%0A%0D%0ATi aspetto!';
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    this._appGlobals.claims = claims;
  }
}
