import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";


@Injectable()
export class AuthGuardService implements CanActivate {
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private authService: MsalService,
    private router: Router,
    private broadcastService: MsalBroadcastService,
  ) { 
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.loginDisplay) {
      if (location.pathname != "/") {
        localStorage.setItem("requestroute", location.pathname);
        this.router.navigate([localStorage.getItem("requestroute")]);
      }
      this.router.navigate(["/"]);
    }

    return true;
  }
}
 