import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  AuthenticationResult,
  InteractionStatus,
} from '@azure/msal-browser';
import { AppGlobalsService } from '../services/app-globals.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ToastService } from '../services/toast.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css'],
  animations: [
    trigger('showhideAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('150ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('150ms', style({ transform: 'translateY(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class JoinComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  public joinForm: FormGroup;
  displayedColumns: string[] = ['claim', 'value'];
  loginDisplay = false;
  userEmail: any;
  submitButtonText = 'ADERISCI';
  talentIdeaToJoin: any;
  fabLabIdeaToJoin: any;
  isOk: any;
  isKo: any;
  TITOLO: any;
  showEmailError: any;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public _appGlobals: AppGlobalsService,
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getClaims(
          this.authService.instance.getActiveAccount()?.idTokenClaims
        );
        this._appGlobals.claims =
          this.authService.instance.getActiveAccount()?.idTokenClaims;
      });

    this.joinForm = this.fb.group({
      CodiceIdea: [null, [Validators.required, Validators.maxLength(254)]],
      EmailProponenteIdea: [
        null,
        [Validators.required, Validators.maxLength(254)],
      ],
    });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    this._appGlobals.claims = claims;
  }

  async onSubmit() {
    if (this.joinForm.valid) {
      this.submitButtonText = 'Attendere prego...';
      const data = this.joinForm.value;
      const userEmail = this._appGlobals.claims.emails[0];

      // ### additional form check: EmailProponenteIdea must not be the logged in user's email/userid
      if (data?.EmailProponenteIdea?.toLowerCase() !== userEmail) {
        // ### check if a talent or fablab idea exists with the required userid and code
        const talentResult = await this.getTalentIdeaByUserIDAndCode(data);
        const fabLabResult = await this.getFabLabIdeaByUserIDAndCode(data);

        if (!!talentResult || !!fabLabResult) {
          // ### if existing (it will be only one, talent or fablab)
          // - show CONFERMA ADESIONE message
          this.isOk = true;

          // - insert (logged in) user as already is done in talent/fablab components
          // - UserId: "" --> as already is in talent/fablab components, it will be automatically filled with the logged in user id
          const claims = this._appGlobals.claims;
          const user = {
            userId: claims.emails[0],
            nome: claims.given_name,
            cognome: claims.family_name,
            email: claims.emails[0],
            telefono: claims.extension_PhoneNumber,
            codiceFiscale: claims.extension_FiscalCode,
            sesso: claims.extension_Gender,
            dataNascita: claims.extension_BirthDate,
            luogoNascita: claims.extension_BirthPlace,
            occupazione: claims.extension_Occupation,
            tipologiaIstruzione: claims.extension_EducationType ?? '',
            dichiarazioneDiPresaVisione: claims.extension_DeclarationOfAcknowledgement.toString(),
            autorizzazioneMailingList: claims.extension_AuthorizationToMailingList.toString(),
            autorizzazioneInvioMateriale: claims.extension_AuthorizationToSendMaterial.toString(),
            autorizzazioneNetworking: claims.extension_AuthorizationToNetworking.toString(),
            autorizzazioneUfficioStampa: claims.extension_AuthorizationToPressOffice.toString(),
            autorizzazionePubblicazione: claims.extension_AuthorizationToPublication.toString()
          };
          this.apiService.addUser(user).subscribe((res) => {
            console.log('user added to share point');
          });

          // - [TITOLO] is the TitoloProgetto from the talentIdeaToJoin
          this.TITOLO = this.talentIdeaToJoin?.titoloProgetto;
          // - insert an idea (talent or fablab depending on which type has been found with the right code) with the following reduced values:
          if (!!talentResult) {
            //Add idea into talent
            let idea = {
              UserId: '',
              Sede: '',
              ComposizioneGruppoLavoro: '',
              TipoStrutturaTeam: '',
              DenominazioneImpresa: '',
              CodiceFiscaleImpresa: '',
              PartitaIvaImpresa: '',
              EmailImpresa: '',
              TitoloProgetto: '',
              DescrizioneProgetto: '',
              Allegato: '',
              AutorizzazioneDirittiNda: false,
              CodiceIdea: data?.CodiceIdea,
              RuoloUtente: 'ADERENTE',
              Status: '',
            };
            this.apiService.addTalentIdea(idea).subscribe(
              (res: any) => {},
              (error) => {
                console.log(error);
              }
            );
          }
          if (!!fabLabResult) {
            // Add idea into fablab
            let idea = {
              UserId: '',
              Sede: '',
              ComposizioneGruppoLavoro: '',
              TipoStrutturaTeam: '',
              DenominazioneImpresa: '',
              CodiceFiscaleImpresa: '',
              PartitaIvaImpresa: '',
              EmailImpresa: '',
              TitoloProgetto: '',
              DescrizioneAmbitoSettoreAppl: '',
              Obiettivo: '',
              DescrizioneBisognoRealizzazione: '',
              TempiStimati: '',
              Allegato: '',
              AutorizzazioneDirittiNda: false,
              CodiceIdea: data?.CodiceIdea,
              RuoloUtente: 'ADERENTE',
              Status: '',
            };
            this.apiService.addFabLabIdea(idea).subscribe(
              (res: any) => {},
              (error) => {
                console.log(error);
              }
            );
          }
        } else {
          // ### if none exist, show "DATI NON RICONOSCIUTI" message
          this.isKo = true;
        }
      } else {
        this.showEmailError = true;
      }

      this.submitButtonText = 'ADERISCI';
    }
  }

  getTalentIdeaByUserIDAndCode(data: any): any {
    return new Promise((resolve) => {
      this.apiService
        .getTalentIdeaByUserIDAndCode(encodeURI(data.EmailProponenteIdea), data.CodiceIdea)
        .subscribe(
          (result) => {
            this.talentIdeaToJoin = result;
            this.isOk = true;
            return resolve(true);
          },
          (error) => {
            console.log(error);
            return resolve(false);
          }
        );
    });
  }

  getFabLabIdeaByUserIDAndCode(data: any): any {
    return new Promise((resolve) => {
      this.apiService
        .getFabLabIdeaByUserIDAndCode(encodeURI(data.EmailProponenteIdea), data.CodiceIdea)
        .subscribe(
          (result) => {
            this.talentIdeaToJoin = result;
            this.isOk = true;
            return resolve(true);
          },
          (error) => {
            console.log(error);
            return resolve(false);
          }
        );
    });
  }
}
