<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h2>Conferma inserimento idea</h2>
      <br />
      <p>La tua idea {{this.ideaFrom}} è stata inserita con successo.</p>
      <p>Sarai contattato dal nostro personale.</p>
      <br />
      <div style="font-size:24px; font-weight:bold;">
        <a href="mailto:?subject={{this.emailLinkSubject}}&body={{this.emailLinkBody}}">Clicca qui</a>
        per invitare altri componenti del team a partecipare al tuo progetto/idea
      </div>
      <br />
      <div>
        Se il link non funzionasse, potresti non avere un client di posta elettronica abilitato. Puoi comunque effettuare l'invito semplicemente inviando una e-mail alla persona interessata indicandogli questi dati:
        <div>
          Url: <a [href]="this.joinUrl"><strong>{{this.joinUrl}}</strong></a>
          <br />
          Codice idea: <strong>{{this.ideaCode}}</strong>
          <br />
          E-mail proponente idea: <strong>{{this.ideaUserEmail}}</strong>
        </div>
      </div>
    </div>
  </div>
</div>