<br>
<app-spinner [isVisible]="!idea" > </app-spinner>
<div class="container" id="main-form" *ngIf="idea">
  <div class="alert alert-danger d-block d-lg-none" style="font-size:24px; line-height:28px;">
      Per favore, ruota il tuo dispositivo mobile in orizzontale per visualizzare correttamente questa pagina
  </div>
  <div class="row">
    <div class="col-md">
      <div *ngIf="this.type=='Talent'">
        <h2>Dettagli idea</h2>
        <br />
        <div class="form-group">
          <strong>Codice idea</strong>
          <br />
          <div style="text-align: justify">
            {{this.ideaCode}}
          </div>
        </div>
        <div class="form-group">
          <strong>Tipologia</strong>
          <br />
          <div style="text-align: justify">
            {{this.type}}
          </div>
        </div>
        <div class="form-group">
          <strong>Data inserimento</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.created | date: 'dd/MM/yyyy HH:mm'}}
          </div>
        </div>
        <div class="form-group">
          <strong>Status</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.status}}
          </div>
        </div>
        <div *ngIf="proponenteAderente == 'PROPONENTE'">
          <div class="form-group">
            <strong>Ruolo</strong>
            <br />
            <div style="text-align: justify">
              PROPONENTE
            </div>
          </div>
        </div>
        <div *ngIf="proponenteAderente == 'ADERENTE'">
          <div class="form-group">
            <strong>Ruolo</strong>
            <br />
            <div style="text-align: justify">
              ADERENTE
            </div>
          </div>
          <div class="form-group">
            <strong>Proponente</strong>
            <br />
            <div style="text-align: justify">
              {{idea?.userId}}
            </div>
          </div>
        </div>
        <div class="form-group">
          <strong>Sede</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.sede}}
          </div>
        </div>
        <div class="form-group">
          <strong>Composizione del gruppo di lavoro</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.composizioneGruppoLavoro}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.composizioneGruppoLavoro=='Team'">
          <strong>Specifica la tipologia di struttura del team</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.tipoStrutturaTeam}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.tipoStrutturaTeam=='Impresa'">
          <strong>Denominazione impresa</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.denominazioneImpresa}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.tipoStrutturaTeam=='Impresa'">
          <strong>Codice fiscale impresa</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.codiceFiscaleImpresa}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.tipoStrutturaTeam=='Impresa'">
          <strong>Partita IVA impresa</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.partitaIvaImpresa}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.tipoStrutturaTeam=='Impresa'">
          <strong>E-mail impresa</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.emailImpresa}}
          </div>
        </div>
        <div class="form-group">
          <strong>Titolo del progetto</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.titoloProgetto}}
          </div>
        </div>
        <div class="form-group">
          <strong>Descrizione del progetto</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.descrizioneProgetto}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea.allegato != null">
          <strong >Scarica allegato</strong>
          <br />
          <a class="btn btn-primary" (click)="getDocument()">Download</a>
        </div>
      </div>
      <div *ngIf="this.type=='FabLab'">
        <h2>Dettagli idea</h2>
        <br />
        <div class="form-group">
          <strong>Codice idea</strong>
          <br />
          <div style="text-align: justify">
            {{this.ideaCode}}
          </div>
        </div>
        <div class="form-group">
          <strong>Tipologia</strong>
          <br />
          <div style="text-align: justify">
            {{this.type}}
          </div>
        </div>
        <div class="form-group">
          <strong>Data inserimento</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.created | date: 'dd/MM/yyyy HH:mm'}}
          </div>
        </div>
        <div class="form-group">
          <strong>Status</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.status}}
          </div>
        </div>
        <div class="form-group">
          <strong>Sede</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.sede}}
          </div>
        </div>
        <div class="form-group">
          <strong>Composizione del gruppo di lavoro</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.composizioneGruppoLavoro}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.composizioneGruppoLavoro=='Team'">
          <strong>Specifica la tipologia di struttura del team</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.tipoStrutturaTeam}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.tipoStrutturaTeam=='Impresa'">
          <strong>Denominazione impresa</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.denominazioneImpresa}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.tipoStrutturaTeam=='Impresa'">
          <strong>Codice fiscale impresa</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.codiceFiscaleImpresa}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.tipoStrutturaTeam=='Impresa'">
          <strong>Partita IVA impresa</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.partitaIvaImpresa}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea?.tipoStrutturaTeam=='Impresa'">
          <strong>E-mail impresa</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.emailImpresa}}
          </div>
        </div>
        <div class="form-group">
          <strong>Titolo del progetto</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.titoloProgetto}}
          </div>
        </div>
        <div class="form-group">
          <strong>Descrizione ambito/settore/applicazione del progetto</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.descrizioneAmbitoSettoreAppl}}
          </div>
        </div>
        <div class="form-group">
          <strong>Obiettivo del progetto</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.obiettivo}}
          </div>
        </div>
        <div class="form-group">
          <strong>Descrizione del bisogno per la realizzazione del progetto</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.descrizioneBisognoRealizzazione}}
          </div>
        </div>
        <div class="form-group">
          <strong>Tempi stimati per la realizzazione del progetto</strong>
          <br />
          <div style="text-align: justify">
            {{idea?.tempiStimati}}
          </div>
        </div>
        <div class="form-group" *ngIf="idea.allegato != null">
          <strong >Scarica allegato</strong>
          <br />
          <a class="btn btn-primary" (click)="getDocument()">Download</a>
        </div>
      </div>
    </div>
    <div class="col-md" *ngIf="(idea.status == 'Approvato post-colloquio' || idea.status == 'Approvato - Avvio a colloquio') && proponenteAderente == 'PROPONENTE'">
      <div class="meeting-details">
        <span class="calendar-link" (click)="openAvailabilityCalendar('Operator')">
          <i class="far fa-calendar-alt icon200"></i>
          <span class="btn reserve">Prenota un colloquio</span>
        </span>
        <mat-card class="card" *ngIf="bookedSlotDetails != null">
          <div class="header-div">
            <p style="text-align: center;"><i class="fa fa-info-circle info-icon" aria-hidden="true"></i></p>
            <p>Hai già un colloquio prenotato per questa idea.</p>
            <h3>Dettagli del colloquio prenotato </h3>
            <mat-divider></mat-divider> 
          </div> 
          <div class="row">
            <div class="col">Sede :</div>
            <div class="col">{{bookedSlotDetails.sede}}</div>
          </div>
          <div class="row">
            <div class="col">Data :</div>
            <div class="col">{{bookedSlotDetails.data | date: 'dd/MM/yyyy'}}</div>
          </div>
          <div class="row">
            <div class="col">Ora inizio :</div>
            <div class="col">{{bookedSlotDetails.oraInizio | date: 'HH:mm'}}</div>
          </div>
          <div class="row">
            <div class="col">Ora fine :</div>
            <div class="col">{{bookedSlotDetails.oraFine | date: 'HH:mm'}}</div> 
          </div>
          <div class="row" *ngIf="bookedSlotDetails.modalitaEsecuzioneSelezionePropo == 'Presenza'">
            <div class="col">Modalità esecuzione :</div>
            <div class="col">Presenza</div>
          </div>
          <div class="row" *ngIf="bookedSlotDetails.modalitaEsecuzioneSelezionePropo == 'Video'">
            <div class="col">Modalità esecuzione :</div>
            <div class="col">Video call</div>
          </div>
          <div class="row" *ngIf="bookedSlotDetails.modalitaEsecuzioneSelezionePropo == 'Video'">
            <div class="col">Piattaforma :</div>
            <div class="col">Microsoft Teams</div>
          </div>
          <div class="row" *ngIf="bookedSlotDetails.modalitaEsecuzioneSelezionePropo == 'Presenza'">
            <div class="col">Indirizzo :</div>
            <div class="col">{{bookedSlotDetails.indirizzoCompletoSede}}</div>
          </div>
          <div class="row" *ngIf="bookedSlotDetails.modalitaEsecuzioneSelezionePropo == 'Video' && bookedSlotDetails.urlTeams != '' && bookedSlotDetails.urlTeams != null">
            <div class="col">URL appuntamento on-line :</div>
            <div class="col"><a target="_blank" href="{{bookedSlotDetails.urlTeams}}">{{bookedSlotDetails.urlTeams}}</a></div>
          </div>
          <div class="row" *ngIf="bookedSlotDetails.modalitaEsecuzioneSelezionePropo == 'Video' && (bookedSlotDetails.urlTeams == '' || bookedSlotDetails.urlTeams == null)">
            <div class="col">URL appuntamento on-line :</div>
            <div class="col">
              ***
              <br />
              Indirizzo in fase di generazione.
              <br />
              I nostri sistemi sono attualmente impegnati nelle procedure di generazione dell'indirizzo Web relativo alla riunione Microsoft Teams.
              <br />
              Ti preghiamo di ricaricare questa pagina tra qualche minuto per visualizzare l'indirizzo generato.
              <br />
              ***
            </div>
          </div>
          <div class="row">
            <div class="col">Operatore :</div>
            <div class="col">{{bookedSlotDetails.operatore}}</div>
          </div>
          <div class="row">
            <div class="col">Contatto telefonico :</div>
            <div class="col">{{bookedSlotDetails.telefonoOperatoreColloquio}}</div>
          </div>
          <div class="row">
            <div class="col">Contatto e-mail :</div>
            <div class="col"><a href="mailto:{{bookedSlotDetails.emailOperatoreColloquio}}">{{bookedSlotDetails.emailOperatoreColloquio}}</a></div>
          </div>
          <div class="row" *ngIf="bookedSlotDetails.noteProponente != '' && bookedSlotDetails.noteProponente != null">
            <div class="col">Note :</div>
            <div class="col">{{bookedSlotDetails.noteProponente}} </div>
          </div>
          <div class="row" *ngIf="this.allowCancel">
            <div class="col">
              Sei impossibilitato a partecipare oppure vuoi riprogrammare questo colloquio?
              <br />
              Cancella prima la prenotazione in corso, utilizzando il pulsante qui sotto. 
            </div>
          </div>
          <div class="row">
            <div class="col" style="text-align:center"><span class="btn cancel-button" *ngIf="this.allowCancel" (click)="CancelSlot(bookedSlotDetails.id)">Cancella questo colloquio</span></div>
          </div>
        </mat-card>
      </div>
      <div class="col-sm" *ngIf="idea.status == 'Approvato post-colloquio' && type?.toLowerCase() == 'talent'">
        <div class="meeting-details">
          <span class="calendar-link" (click)="openAvailabilityCalendar('Spazi')">
            <i class="fa fa-people-arrows icon200"></i>
            <span class="btn reserve">Prenota una postazione</span>
          </span>
        </div>
      </div>
      <div class="col-sm" *ngIf="idea.status == 'Approvato post-colloquio' && type?.toLowerCase() == 'fablab'">
        <div class="meeting-details">
          <span class="calendar-link" (click)="openAvailabilityCalendar('Macchine')">
            <i class="fas fa-screwdriver-wrench icon200"></i>
            <span class="btn reserve">Prenota un dispositivo</span>
          </span>
        </div>
      </div>
    </div>
    
  </div>  
  
  <div *ngIf="proponenteAderente == 'PROPONENTE'">
    
    <div *ngIf="slotLists['macchine'].length > 0">
      <br />
      <hr />
      <br />
      <h2>Dispositivi</h2>
      <br/>
        <table class="table table-bordered table-condensed table-hover" >
          <thead>
            <tr>
              <th scope="col" style="text-align: center;" >Data</th>
              <th scope="col" style="text-align: center;" >Ora Inizio</th>
              <th scope="col" style="text-align: center;" >Ora Fine</th>
              <th scope="col" style="text-align: center;" >Sede</th>
              <th scope="col" style="text-align: center;" >Macchina</th>
              <th scope="col" style="text-align: center;" >Categoria</th>
              <th scope="col" style="text-align: center;" >Status</th>
              <th scope="col" style="text-align: center;" ></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of slotLists['macchine'];">
              <td style="text-align: center;">{{item?.data | date: 'dd/MM/yyyy'}}</td>
              <td style="text-align: center;">{{item?.oraInizio | date: 'HH:mm'}}</td>
              <td style="text-align: center;">{{item?.oraFine | date: 'HH:mm'}}</td>
              <td style="text-align: center;">{{item?.sede}}</td>
              <td style="text-align: center;">{{item?.macchina}}</td>
              <td style="text-align: center;">{{item?.categoria}}</td>
              <td style="text-align: center;">{{item?.status}}</td>
              <td style="text-align: center;"><a class="btn cancel-button-small btn-sm" *ngIf="item?.status == 'Prenotato'" (click)="CancelSingleSlot(item?.id)"> Cancella </a></td>
            </tr>
          </tbody>
        </table>
    </div>
    
    <div *ngIf="slotLists['spazi'].length > 0">
      <br />
      <hr />
      <br />
      <h2>Postazioni</h2>
      <br/>
        <table class="table table-bordered table-condensed table-hover" >
          <thead>
            <tr>
              <th scope="col" style="text-align: center;" >Data</th>
              <th scope="col" style="text-align: center;" >Ora Inizio</th>
              <th scope="col" style="text-align: center;" >Ora Fine</th>
              <th scope="col" style="text-align: center;" >Sede</th>
              <th scope="col" style="text-align: center;" >Postazione</th>
              <th scope="col" style="text-align: center;" >Capienza</th>
              <th scope="col" style="text-align: center;" >Status</th>
              <th scope="col" style="text-align: center;" ></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of slotLists['spazi'];">
              <td style="text-align: center;">{{item?.data | date: 'dd/MM/yyyy'}}</td>
              <td style="text-align: center;">{{item?.oraInizio | date: 'HH:mm'}}</td>
              <td style="text-align: center;">{{item?.oraFine | date: 'HH:mm'}}</td>
              <td style="text-align: center;">{{item?.sede}}</td>
              <td style="text-align: center;">{{item?.spazio}}</td>
              <td style="text-align: center;">{{item?.capienza}}</td>
              <td style="text-align: center;">{{item?.status}}</td>
              <td style="text-align: center;"><a class="btn cancel-button-small btn-sm" *ngIf="item?.status == 'Prenotato'" (click)="CancelSingleSlot(item?.id)"> Cancella </a></td>
            </tr>
          </tbody>
        </table>
    </div>
    
    <div *ngIf="slotLists['operator'].length > 0">
      <br />
      <hr />
      <br />
      <h2>Colloqui</h2>
      <br/>
        <table class="table table-bordered table-condensed table-hover" >
          <thead>
            <tr>
              <th scope="col" style="text-align: center;" >Data</th>
              <th scope="col" style="text-align: center;" >Ora Inizio</th>
              <th scope="col" style="text-align: center;" >Ora Fine</th>
              <th scope="col" style="text-align: center;" >Sede</th>
              <th scope="col" style="text-align: center;" >Operatore</th>
              <th scope="col" style="text-align: center;" >Modalità</th>
              <th scope="col" style="text-align: center;" >Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of slotLists['operator'];">
              <td style="text-align: center;">{{item?.data | date: 'dd/MM/yyyy'}}</td>
              <td style="text-align: center;">{{item?.oraInizio | date: 'HH:mm'}}</td>
              <td style="text-align: center;">{{item?.oraFine | date: 'HH:mm'}}</td>
              <td style="text-align: center;">{{item?.sede}}</td>
              <td style="text-align: center;">{{item?.operatore}}</td>
              <td style="text-align: center;">
                <i *ngIf="item?.modalitaEsecuzioneSelezionePropo == 'Presenza'" class="fas fa-user-friends icon-table" matTooltip="Presenza" matTooltipPosition="after"></i>
                <i *ngIf="item?.modalitaEsecuzioneSelezionePropo == 'Video' && item?.urlTeams == ''" class="fas fa-desktop icon-table" matTooltip="Video call" matTooltipPosition="after"></i>
                <a *ngIf="item?.modalitaEsecuzioneSelezionePropo == 'Video' && item?.urlTeams != ''" target="_blank" href="{{item?.urlTeams}}"><i class="fas fa-desktop icon-table" matTooltip="Video call - Fai clic qui per accedere tramite Microsoft Teams" matTooltipPosition="after"></i></a>
              </td>
              <td style="text-align: center;">{{item?.status}}</td>
            </tr>
          </tbody>
        </table>
    </div>
    
    <br />
    <hr />
    <br />
    <h2>Adesione</h2>
    <br />
    <h3>Invita altre persone a partecipare al tuo progetto/idea</h3>
    <div>
      <br />
      <a href="mailto:?subject={{this.emailLinkSubject}}&body={{this.emailLinkBody}}">Clicca qui</a>
      per invitare altri componenti del team a partecipare al tuo progetto/idea
    </div>
    <br />
    <div>
      Se il link non funzionasse, potresti non avere un client di posta elettronica abilitato. Puoi comunque effettuare l'invito semplicemente inviando una e-mail alla persona interessata indicandogli questi dati:
      <div>
        <br />
        Url: <a [href]="this.joinUrl"><strong>{{this.joinUrl}}</strong></a>
        <br /><br />
        Codice idea: <strong>{{this.ideaCode}}</strong>
        <br /><br />
        E-mail proponente idea: <strong>{{this.ideaUserEmail}}</strong>
    </div>
    </div>
    <br /><br />

  </div>

</div>
