import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import {
  ConfimationDialogComponent,
  ConfirmDialogModel,
} from '../confimation-dialog/confimation-dialog.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-availability-list',
  templateUrl: './availability-list.component.html',
  styleUrls: ['./availability-list.component.css'],
})
export class AvailabilityListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  moment: any = moment;
  dateString: string;
  Sede: string;
  Categoria: string = 'All';
  resultLength: number = 0;
  dt: Date = new Date();
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  idea: any = {};
  locations = [];
  type: string;
  screen: string;
  code: string;
  reserveConfirmMessageName: string;
  slotBooked: boolean = false;
  ModalitaEsecuzioneSelezioneProponente: string;
  constructor(
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.idea = JSON.parse(localStorage['ideaDetails']);
    this.dateString = this.route.snapshot.params.dateString;
    this.code = this.route.snapshot.params.code;
    this.type = this.route.snapshot.params.type;
    this.Sede = this.route.snapshot.params.location;
    this.screen = this.route.snapshot.params.screen.toLowerCase();
    this.Categoria = this.type.toLowerCase() == 'fablab' ? 'FabLab' : 'Talent';
    this.dt = new Date(this.dateString);

    if (this.screen == "operator") {
      this.displayedColumns = [
        'data',
        'oraInizio',
        'oraFine',
        'sede',
        'operatore',
        'modalitaEsecuzione',
        'status',
      ];
      this.reserveConfirmMessageName = "questo colloquio";
    }
    if (this.screen == "spazi") {
      this.displayedColumns = [
        'data',
        'oraInizio',
        'oraFine',
        'sede',
        'spazio',
        'capienza',
        'status',
      ];
      this.reserveConfirmMessageName = "questa postazione";
    }
    else if (this.screen == "macchine") {
      this.displayedColumns = [
        'data',
        'oraInizio',
        'oraFine',
        'sede',
        'macchina',
        'categoria',
        'status',
      ];
      this.reserveConfirmMessageName = "questo dispositivo";
    }

    this.dateString = moment(this.dt).format('D MMMM yyyy');
    this.getLocations();
    this.checkReservation();
  }

  ReserveSlot(item: any) {
    if (this.screen.toLowerCase() != 'operator' || !this.slotBooked) {
      this.openDialog(
        'Conferma',
        'Confermi di voler procedere alla prenotazione di ' + this.reserveConfirmMessageName + '?',
        'reserve-confirmation',
        'question-circle',
        this.screen,
        item.modalitaEsecuzione,
        (action: any) => {
          if (action) {
            if (this.screen.toLowerCase() == 'operator' && item.modalitaEsecuzione == 'Presenza'){
              this.ModalitaEsecuzioneSelezioneProponente = action.ModalitaEsecuzione;
            }
            else {
              this.ModalitaEsecuzioneSelezioneProponente = "";
            }
            console.log(action.ModalitaEsecuzione);
            this.apiService
              .updateSlots(
                this.apiService.getControllerName(this.screen),
                item.id,
                'Prenotato',
                this.idea['codiceIdea'],
                this.ModalitaEsecuzioneSelezioneProponente,
                '',
                action.NoteProponente,
              )
              .subscribe((result: any) => {
                this.openDialog(
                  'Operazione eseguita',
                  'La tua prenotazione è stata registrata con successo.',
                  'success',
                  'check-circle',
                  '',
                  '',
                  (action: any) => {
                    this.router.navigate(['/idea-details', this.type, this.code]);
                  }
                );
                //this.getAvailabilitySlotsList();
              });
          }
        }
      );
    } else {
      this.openDialog(
        'Operazione negata',
        'Hai già una prenotazione per questa idea.<br/>Sei impossibilitato a partecipare oppure vuoi riprogrammare il tuo colloquio?<br/>Cancella prima la prenotazione in corso, utilizzando gli appositi strumenti presenti nella scheda dell’idea.',
        'error',
        'times-circle',
        '',
        ''
      );
    }
  }

  openDialog(
    title: string,
    message: string,
    type: string,
    icon: string,
    screen: string,
    executionMode: string,
    callback?: any
  ): void {
    const dialogData = new ConfirmDialogModel(title, message, type, icon, screen, executionMode);
    const dialogRef = this.dialog.open(ConfimationDialogComponent, {
      maxWidth: '500px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (callback) callback(dialogResult);
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'test',
    });
  }

  checkReservation() {
    this.apiService
      .checkReservation(
        this.apiService.getControllerName(this.screen),
        this.idea['codiceIdea']
      )
      .subscribe((result) => {
        var resp: any = result;
        this.slotBooked = resp['isSuccess'];
      });
  }
  getAvailabilitySlotsList() {
    this.apiService
      .getAvailabilityListing(
        this.apiService.getControllerName(this.screen),
        this.Sede,
        this.Categoria,
        moment(this.dt).format('yyyy-MM-DD')
      )
      .subscribe((result: any) => {
        this.resultLength = result.value.length;
        this.dataSource = new MatTableDataSource(result.value);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
  getLocations() {
    this.apiService.getLocations(this.type).subscribe((result: any) => {
      this.locations = result.value;
    });
  }
  ngAfterViewInit() {
    this.getAvailabilitySlotsList();
  }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Righe per pagina';
  }

  GotoCalendar() {
    this.router.navigate(['/availability', this.screen, this.type, this.code]);
  }

  dayChanged(action: string) {
    var day = action == 'Prev' ? -1 : 1;
    this.dt.setDate(this.dt.getDate() + day);
    this.dateString = moment(this.dt).format('D MMMM yyyy');
    this.getAvailabilitySlotsList();
  }
}
