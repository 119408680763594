// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  clientId: "54ddfe92-45c2-49ca-bb16-4bbf1a399998",
  authorityDomin: "b2clazioinnova.onmicrosoft.com",
  azureB2cUrl: "https://b2clazioinnova.b2clogin.com/b2clazioinnova.onmicrosoft.com/",
  signUpSignInPolicy: "B2C_1_SignUpIn",
  editProfilePolicy: "B2C_1_ProfileEdit",
  redirectUrl: "https://fablabtalent.lazioinnova.it/",
  authorityDomain: "b2clazioinnova.onmicrosoft.com",
  apiUrl: "https://lazioinnova-sa-fablabtalent-webapi-prod.azurewebsites.net/api"
};
// redirectUrl: 'http://localhost:4200',

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
