<div class="container">
  <div class="alert alert-danger d-block d-lg-none" style="font-size:24px; line-height:28px;">
      Per favore, ruota il tuo dispositivo mobile in orizzontale per visualizzare correttamente questa pagina
  </div>
  <div class="row align-items-center mb-3">
      <div class="col-lg-4 mb-3">
        Seleziona la sede
        <select class="form-control" [(ngModel)]="Sede" name="Sede" (change)="getAvailabilitySlotsList()" >
          <option  value="All">All</option >
          <option *ngFor="let location of locations" value="{{location['sede']}}">{{location["sede"]}}</option>
        </select>
      </div>
      <div class="col-lg-4 calendar-controls text-nowrap">
          <i class="fas fa-angle-double-left icon" (click)="dayChanged('Prev')"></i>
          <span class="dateString">{{dateString}}</span>
          <i class="fas fa-angle-double-right icon" (click)="dayChanged('Next')"></i>
      </div>
      <div class="col-lg-4 text-right">
        <button class="btn btn-primary" (click)="GotoCalendar()">Torna al calendario</button>
      </div>
  </div>
  <div class="row">
    <div class="col">
      <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z3">

        <ng-container matColumnDef="data">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Data</span></th>
          <td mat-cell *matCellDef="let element"> {{element.data | date: 'dd/MM/yyyy'}} </td>
        </ng-container>
            
        <ng-container matColumnDef="oraInizio">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Ora Inizio</span></th>
          <td mat-cell *matCellDef="let element"> {{element.oraInizio | date: 'HH:mm'}} </td>
        </ng-container>
      
        <ng-container matColumnDef="oraFine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Ora Fine</span></th>
          <td mat-cell *matCellDef="let element"> {{element.oraFine | date: 'HH:mm'}} </td>
        </ng-container>
      
        <ng-container matColumnDef="sede">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Sede</span></th>
          <td mat-cell *matCellDef="let element"> {{element.sede}} </td>
        </ng-container>
      
        <ng-container matColumnDef="operatore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Operatore</span></th>
          <td mat-cell *matCellDef="let element"> {{element.operatore}} </td>
        </ng-container>
        
        <ng-container matColumnDef="modalitaEsecuzione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Modalità</span></th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.modalitaEsecuzione == 'Presenza'">
              <i class="fas fa-desktop icon-mode" matTooltip="Video call" matTooltipPosition="after"></i>
              <i class="fas fa-user-friends icon-mode" matTooltip="Presenza" matTooltipPosition="after"></i>
            </div>
            <div *ngIf="element.modalitaEsecuzione == 'Video'">
              <i class="fas fa-desktop icon-mode" matTooltip="Video call" matTooltipPosition="after"></i>
            </div>
          </td>
        </ng-container>
      
        <ng-container matColumnDef="spazio">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Postazione</span></th>
          <td mat-cell *matCellDef="let element"> {{element.spazio}} </td>
        </ng-container>
      
        <ng-container matColumnDef="capienza">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Capienza</span></th>
          <td mat-cell *matCellDef="let element"> {{element.capienza}} </td>
        </ng-container>
      
        <ng-container matColumnDef="macchina">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Macchina</span></th>
          <td mat-cell *matCellDef="let element"> {{element.macchina}} </td>
        </ng-container>
      
        <ng-container matColumnDef="categoria">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="th-header">Categoria</span></th>
          <td mat-cell *matCellDef="let element"> {{element.categoria}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef><span class="th-header">Status</span></th>
          <td mat-cell *matCellDef="let element"> 
            <a class="btn btn-primary btn-sm" (click)="ReserveSlot(element)"> PRENOTA </a>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">Nessuna disponibilità per questo giorno</td>
        </tr>
      </table>
      <mat-paginator [length]="resultLength" [pageSize]="50" [pageSizeOptions]="[ 10, 20, 50, 100]" aria-label="Select page" itemsPerPageLabel="'Test'" #MatPaginator></mat-paginator>
    </div>
  </div>
  <div class="row top-margin">
    <div class="col-12 right-align">
      <button class="btn btn-primary" (click)="GotoCalendar()">Torna al calendario</button>
    </div>
  </div>
</div>
