<br>
<br>

<div class="container" id="main-form" *ngIf="!isKo && !isOk">
  <div class="row">
    <div class="col">
      <h2>Aderisci a un'idea</h2>
      <br />
      <div>Se vuoi accettare l’invito ad aderire a un’idea presentata da un altro utente, compila i seguenti campi:</div> <br>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-lg-5">
      <img src="assets/images/batti5.jpg" style="width:100%;" />
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <form [formGroup]="joinForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <label for="CodiceIdea">Codice Idea</label>
              <input type="text" class="form-control" formControlName="CodiceIdea" id="CodiceIdea" placeholder="">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <label for="EmailProponenteIdea">E-mail proponente Idea</label>
              <input type="text" class="form-control" formControlName="EmailProponenteIdea" id="EmailProponenteIdea" placeholder="">
              <small *ngIf="showEmailError" class="text-danger">Non è possibile utilizzare l'indirizzo e-mail di un utente già connesso.</small>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <button type="submit" [disabled]="!joinForm.valid" class="btn btn-primary" [ngClass]="!joinForm.valid ? 'btn-disabled' : ''">
                <span *ngIf="submitButtonText != 'ADERISCI'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span>
                  {{submitButtonText}}
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="container" *ngIf="isKo">
  <div class="row">
    <div class="col">
      <h2>Aderisci a un'idea - dati non riconosciuti</h2>
      <br />
      <p>Siamo spiacenti ma i dati da te inseriti non sono riconosciuti come validi. </p>
      <p>Ti preghiamo di riprovare ad inserirli oppure contattare il proponente dell’idea per controllare l’esattezza dei dati invito in tuo possesso.</p>
    </div>
  </div>
</div>

<div class="container" *ngIf="isOk">
  <div class="row">
    <div class="col">
      <h2>Aderisci a un'idea - conferma adesione</h2>
      <br />
      <p>Congratulazioni! Hai aderito all'idea denominata:<br /><strong>{{TITOLO}}</strong>.</p>
      <p>A presto!</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-lg-5">
      <img src="assets/images/batti5.jpg" style="width:100%;" />
    </div>
  </div>
</div>