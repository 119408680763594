import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import {
  Router,
} from '@angular/router';
import 'rxjs/add/operator/do';
import { MsalService } from '@azure/msal-angular';
import { AppGlobalsService } from '../services/app-globals.service';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private router: Router, private msalService: MsalService, private _appGlobals: AppGlobalsService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.msalService.instance && request.url.startsWith(environment.apiUrl)) {
      const loginRequest =
        [
          'openid',
          'profile',
          'https://b2clazioinnova.onmicrosoft.com/d1c8c06c-73d3-4944-9226-d95ba4af1534/API'
        ];

      return from(
        this.msalService.acquireTokenSilent({ scopes: loginRequest, authority: `${environment.azureB2cUrl}B2C_1_SignUpIn` })
          .toPromise()
          .then((response:AuthenticationResult) => {
                const token = response.idToken;
                const authHeader = `Bearer ${token}`;
                return request.clone({
                    setHeaders: {
                        Authorization: authHeader,
                    }
                });

            })
      )
        .pipe(
            mergeMap(nextReq => next.handle(nextReq)),
            tap(
              event => { },
              err => {
                  if (err) {
                      const iframes = document.querySelectorAll('iframe');
                      iframes.forEach(element => {
                        element?.parentNode?.removeChild(element);
                      });
                    }
              }
          ));
    } else {
      return next.handle(request);
    }
  }
}
