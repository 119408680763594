import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

constructor() { }
public getDocument(data:string) {
  const binary = this.convertDataURIToBinary(data);
  const blob = new Blob([ binary ],{ type: 'application/pdf' });
  const blobUrl = URL.createObjectURL(blob);
  return window.open(blobUrl, '_blank');
}
private convertDataURIToBinary(dataStr: string) {
  let data = window.atob(dataStr);
  const rawLength = data.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));
  for (let i = 0; i < rawLength; i++) {
    array[ i ] = data.charCodeAt(i);
  }
  return array;
}
}
