<br>
<br>

<div class="container" id="main-form" *ngIf="!loginDisplay">
  <div class="row">
    <div class="col">
      <h2>Ciao,</h2>
      <br />
      <div>ti diamo il benvenuto nell'app del <strong>Talentworking</strong> e <strong>FabLab</strong> della Regione Lazio</div>
      <br>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a class="link-block-login" (click)="login()"style="cursor:pointer; color:white;">
        <div>
          Accedi o Registrati
        </div>
      </a>
      <div class="clearfix"></div>
    </div>
  </div>
  
</div>

<div class="container" id="main-form" *ngIf="loginDisplay">
  <div class="row">
    <div class="col">
      <h2>Ciao {{ _appGlobals?.claims?.given_name }},</h2>
      <br />
      <div>presentaci la tua idea oppure utilizza gli altri strumenti a tua disposizione!</div>
      <br>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a class="col link-block" [routerLink]="['/talent']">
        <div>
          INSERISCI IDEA TALENT
        </div>
      </a>
      <a class="col link-block" [routerLink]="['/fablab']">
        <div>
          INSERISCI IDEA FABLAB
        </div>
      </a>
      <div class="clearfix"></div>
      <a class="col link-block" [routerLink]="['/join']">
        <div>
          ADERISCI AD UNA IDEA
        </div>
      </a>
      <a class="col link-block" [routerLink]="['/ideas']">
        <div>
          VISUALIZZA LE TUE IDEE
        </div>
      </a>
      <div class="clearfix"></div>
    </div>
  </div>
  
</div>
