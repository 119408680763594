import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class AppGlobalsService {
  public claims: any | undefined;
  constructor() { }

}
