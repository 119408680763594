<div class="container">
    <div class="alert alert-danger d-block d-lg-none" style="font-size:24px; line-height:28px;">
        Per favore, ruota il tuo dispositivo mobile in orizzontale per visualizzare correttamente questa pagina
    </div>
    <div class="row">
        <div class="col ">
            <h2>Calendario disponibilità {{pluralName}}</h2>
            <br/>
            <div style="font-size: larger; font-weight: bold;">
            Visiona le disponibilità per fissare {{singularName}}, a partire dalla sede di riferimento della tua idea.
Clicca sul giorno dove sono presenti disponibilità per visionare l’elenco di dettaglio e prenotare {{singularName}}.
            </div>
            <br/>
        </div>
    </div>
    <div class="row align-items-center mb-3">
        <div class="col-lg-4 mb-3">
            Seleziona la sede
            <select class="form-control" [(ngModel)]="Sede" name="Sede" (change)="getAvailableSlots()" >
                <option *ngFor="let location of locations" value="{{location['sede']}}">{{location["sede"]}}</option>
            </select>
        </div>
        <div class="col-lg-4 calendar-controls text-nowrap">
            <i class="fas fa-angle-double-left icon" (click)="monthChanged('Prev')"></i>
            <span class="dateString">{{calendarMonth}} {{calendarYear}}</span>
            <i class="fas fa-angle-double-right icon" (click)="monthChanged('Next')"></i>
        </div>
        <div class="col-lg-4 text-right">
            <button class="btn btn-primary" (click)="gotoIdeaDetails()">Torna all’idea</button>
        </div>
    </div>
</div>


<full-calendar [options]="calendarOptions" [customButtons]="calendarOptions.customButtons" #calendar ></full-calendar>