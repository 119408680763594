import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  AuthenticationResult,
  InteractionStatus,
} from '@azure/msal-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { DocumentService } from '../services/document.service';
import { AppGlobalsService } from '../services/app-globals.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfimationDialogComponent,
  ConfirmDialogModel,
} from '../confimation-dialog/confimation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { now } from 'moment';
import { ShorthandPropertyAssignment } from 'typescript';
@Component({
  selector: 'app-idea-details',
  templateUrl: './idea-details.component.html',
  styleUrls: ['./idea-details.component.scss'],
})
export class IdeaDetailsComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  loginDisplay = false;
  code: string | null;
  type: string | null;
  screen: string;
  idea: any;
  joinUrl = '';
  ideaCode = '';
  ideaUserEmail = '';
  loggedUserEmail = '';
  emailLinkSubject = '';
  emailLinkBody = '';
  bookedSlotDetails: any;
  slotLists: any = {
    operator: [],
    macchine: [],
    spazi: [],
  };
  allowCancel: boolean = true;
  proponenteAderente: string = "";
  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public _appGlobals: AppGlobalsService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private documentService: DocumentService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    //this.screen = idea.status == 'Approvato post colloquio' && this.type?.toLowerCase() == 'talent';
  }

  getSlotsHistory() {
    let screen = this.screen.toLowerCase();
    this.apiService
      .getSlotsHistory(this.apiService.getControllerName(screen), this.ideaCode)
      .subscribe((result) => {
        var resp: any = result;
        this.slotLists[screen] = resp['value'];
        console.log(this.slotLists);
      });
  }

  checkReservation() {
    this.getSlotsHistory();
    this.apiService
      .checkReservation(
        this.apiService.getControllerName('operator'),
        this.ideaCode
      )
      .subscribe((result) => {
        var resp: any = result;
        this.bookedSlotDetails = null;
        if (resp['isSuccess']) {
          this.bookedSlotDetails = resp.data;
          this.allowCancel =
            new Date(this.bookedSlotDetails.oraInizio) > new Date();
          console.log('Cancellation : ' + this.allowCancel);
        }
      });
  }

  openDialog(
    title: string,
    message: string,
    type: string,
    icon: string,
    callback?: any
  ): void {
    const dialogData = new ConfirmDialogModel(title, message, type, icon);

    const dialogRef = this.dialog.open(ConfimationDialogComponent, {
      maxWidth: '500px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (callback) callback(dialogResult);
    });
  }

  CancelSlot(slotId: number) {
    if (this.allowCancel) {
      this.openDialog(
        'Conferma',
        'Confermi di voler procedere alla cancellazione di questo colloquio?',
        'cancel-confirmation',
        'question-circle',
        (action: boolean) => {
          if (action) {
            this.apiService
              .updateSlots(
                'SlotColloqui',
                slotId,
                'Disponibile',
                '',
                '',
                '',
                ''
              )
              .subscribe((result: any) => {
                console.log(result);
                this.openDialog(
                  'Operazione eseguita',
                  'La tua prenotazione è stata cancellata. Ora puoi procedere ad una nuova prenotazione.',
                  'success',
                  'check-circle'
                );
                this.checkReservation();
              });
          }
        }
      );
    }
  }

  CancelSingleSlot(slotId: number) {
    this.openDialog(
      'Conferma',
      'Confermi di voler procedere alla cancellazione di questa prenotazione?',
      'cancel-confirmation',
      'question-circle',
      (action: boolean) => {
        if (action) {
          this.apiService
            .updateSlots(
              this.apiService.getControllerName(this.screen),
              slotId,
              'Disponibile',
              '',
              '',
              '',
              ''
            )
            .subscribe((result: any) => {
              console.log(result);
              this.openDialog(
                'Operazione eseguita',
                'La tua prenotazione è stata cancellata. Ora puoi procedere ad una nuova prenotazione.',
                'success',
                'check-circle'
              );
              this.checkReservation();
            });
        }
      }
    );
  }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.getClaims(
          this.authService.instance.getActiveAccount()?.idTokenClaims
        );
        this._appGlobals.claims =
          this.authService.instance.getActiveAccount()?.idTokenClaims;
      });

    this.code = this.route.snapshot.paramMap.get('code');
    this.type = this.route.snapshot.paramMap.get('type');
    this.screen = 'operator';
    if (this.code && this.type) {
      if (this.type == 'Talent') {
        this.apiService.getTalentIdea(this.code).subscribe((result) => {
          this.idea = result;
          this.ideaCode = this.idea.codiceIdea;
          this.joinUrl = `${window.location.origin}/join`;
          this.ideaUserEmail = this.idea.userId;
          this.loggedUserEmail = this._appGlobals.claims.emails[0];
          this.getSlotsHistory();
          //debugger;
          if (this.idea.status == 'Approvato post-colloquio') {
            this.screen = 'spazi';
          }
          this.setEmailLink();
          this.checkReservation();
          this.setProponenteAderente();
        });
      } else {
        this.apiService.getFabLabIdea(this.code).subscribe((result) => {
          this.idea = result;
          this.ideaCode = this.idea.codiceIdea;
          this.joinUrl = `${window.location.origin}/join`;
          this.ideaUserEmail = this.idea.userId;
          this.loggedUserEmail = this._appGlobals.claims.emails[0];
          this.getSlotsHistory();
          //debugger;
          if (this.idea.status == 'Approvato post-colloquio') {
            this.screen = 'macchine';
          }
          this.setEmailLink();
          this.checkReservation();
          this.setProponenteAderente();
        });
      }
    }
  }
  public openAvailabilityCalendar(screen: string) {
    localStorage['ideaDetails'] = JSON.stringify(this.idea);
    this.router.navigate(['/availability', screen, this.type, this.code]);
  }

  public getDocument() {
    this.documentService.getDocument(this.idea.allegato);
  }

  setEmailLink() {
    this.emailLinkSubject = 'Aderisci al mio progetto/idea';
    this.emailLinkSubject += ' ' + this.type;
    this.emailLinkBody =
      'Ciao,%0D%0Aecco le informazioni per aderire al mio progetto/idea:%0D%0A%0D%0A- collegati a questo indirizzo ' +
      this.joinUrl +
      '%0D%0A- registra la tua utenza o accedi con le tue credenziali (se ne sei già in possesso)%0D%0A- accedi alla pagina ADERISCI AD UNA IDEA%0D%0A- inserisci il CODICE IDEA: ' +
      this.ideaCode +
      "%0D%0A- inserisci l'indirizzo email del proponente della idea: " +
      this.ideaUserEmail +
      '%0D%0A%0D%0ATi aspetto!';
  }

  setProponenteAderente() {
    if (this.ideaUserEmail == this.loggedUserEmail) {
      this.proponenteAderente = "PROPONENTE";
    }
    else {
      this.proponenteAderente = "ADERENTE";
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    this._appGlobals.claims = claims;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
