<br>
<br>

<div class="container" id="main-form" *ngIf="!showThankYou">
  <div class="row">
    <div class="col">
      <h2>Talent: Inserisci la tua idea</h2>
      <br />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-lg-3">
      <img src="assets/images/lampadina.jpg" style="width:100%;" />
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12">
      <div style="font-size:larger;font-weight: bold;" >
        Compila la scheda
      </div>
      <br>
      <form [formGroup]="newIdeaForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <label for="Sede">Sede Spazio Attivo</label>
              <select class="form-control" id="Sede" formControlName="Sede" >
                <option></option>
                <option *ngFor="let location of locations" value="{{location['sede']}}">{{location["sede"]}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <label for="ComposizioneGruppoLavoro">Composizione del gruppo di lavoro</label>
              <select class="form-control" id="ComposizioneGruppoLavoro" formControlName="ComposizioneGruppoLavoro" (change)="toggle_blocco_team()" >
                <option></option>
                <option>Individuale</option>
                <option>Team</option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="display_blocco_team" [@showhideAnimation]>
          <div class="row">
            <div class="col-lg-7">
              <div class="form-group">
                <label for="TipoStrutturaTeam">Specifica la tipologia di struttura del team</label>
                <select class="form-control" id="TipoStrutturaTeam" formControlName="TipoStrutturaTeam" (change)="toggle_blocco_team_impresa()" >
                  <option></option>
                  <option>Team informale</option>
                  <option>Impresa</option>
                </select>
              </div>
            </div>
          </div>
          <div *ngIf="display_blocco_team_impresa" [@showhideAnimation]>
            <div class="row">
              <div class="col-lg-7">
                <div class="form-group">
                  <label for="DenominazioneImpresa">Denominazione impresa (max 254 caratteri)</label>
                  <input type="text" class="form-control" formControlName="DenominazioneImpresa" id="DenominazioneImpresa" placeholder="">
                  <div class="ng-invalid" *ngIf="newIdeaForm.controls['DenominazioneImpresa'].errors?.maxlength">
                    Il testo inserito è troppo lungo, inserire un testo più corto (max 254 caratteri)
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7">
                <div class="form-group">
                  <label for="CodiceFiscaleImpresa">Codice fiscale impresa</label>
                  <input type="text" class="form-control" formControlName="CodiceFiscaleImpresa" id="CodiceFiscaleImpresa" placeholder="">
                  <div class="ng-invalid" *ngIf="newIdeaForm.controls['CodiceFiscaleImpresa'].errors?.maxlength">
                    Il testo inserito è troppo lungo, inserire un testo più corto (max 254 caratteri)
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7">
                <div class="form-group">
                  <label for="PartitaIvaImpresa">Partita IVA impresa</label>
                  <input type="text" class="form-control" formControlName="PartitaIvaImpresa" id="PartitaIvaImpresa" placeholder="">
                  <div class="ng-invalid" *ngIf="newIdeaForm.controls['PartitaIvaImpresa'].errors?.maxlength">
                    Il testo inserito è troppo lungo, inserire un testo più corto (max 254 caratteri)
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7">
                <div class="form-group">
                  <label for="EmailImpresa">E-mail impresa</label>
                  <input type="text" class="form-control" formControlName="EmailImpresa" id="EmailImpresa" placeholder="">
                  <div class="ng-invalid" *ngIf="newIdeaForm.controls['EmailImpresa'].errors?.maxlength">
                    Il testo inserito è troppo lungo, inserire un testo più corto (max 254 caratteri)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <label for="TitoloProgetto">Titolo del progetto (max 254 caratteri)</label>
              <input type="text" class="form-control" formControlName="TitoloProgetto" id="TitoloProgetto" placeholder="">
              <div class="ng-invalid" *ngIf="newIdeaForm.controls['TitoloProgetto'].errors?.maxlength">
                Il testo inserito è troppo lungo, inserire un testo più corto (max 254 caratteri)
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <label for="DescrizioneProgetto">Descrizione del progetto (max 4.000 caratteri)</label>
              <textarea class="form-control" id="DescrizioneProgetto" formControlName="DescrizioneProgetto" rows="5"></textarea>
              <div class="ng-invalid" *ngIf="newIdeaForm.controls['DescrizioneProgetto'].errors?.maxlength">
                Il testo inserito è troppo lungo, inserire un testo più corto (max 4.000 caratteri)
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <label for="Allegato">
                Allegato (facoltativo)
                <br />
                Caricare qui la presentazione dettagliata del tuo progetto/idea
                <br />
                (accettati esclusivamente formati .PDF o .ZIP, dimensione massima 28MB)
              </label>
              <input type="file" class="form-control-file" accept="application/pdf, application/zip" id="Allegato" (change)="onChange($event)" formControlName="Allegato">
            </div>
          </div>
        </div>
        <!--
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <input type="checkbox" id="AutorizzazioneDirittiNda" formControlName="AutorizzazioneDirittiNda" style="display:inline-block; margin-right:10px; line-height:30px; margin-bottom:8px;" />
              <label for="AutorizzazioneDirittiNda">Autorizzazione diritti / NDA <a href="assets/TEST PDF.pdf" target="_blank">[visualizza informativa]</a></label>
            </div>
          </div>
        </div>
        -->
        <br />
        <div class="row">
          <div class="col-lg-7">
            <div class="form-group">
              <button type="submit" [disabled]="!newIdeaForm.valid" class="btn btn-primary" [ngClass]="!newIdeaForm.valid ? 'btn-disabled' : ''">
                <span *ngIf="submitButtonText != 'INVIA'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span>
                  {{submitButtonText}}
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-thank-you *ngIf="showThankYou" [codiceIdea]="ideaCode" [ideaFrom]="'TALENT'"></app-thank-you>