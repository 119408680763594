import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { AppinsightsService } from './appinsights.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  baseUrl = '';
  private httpClientWithOutInterceptor: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient,private toast: ToastService,private appInsight:AppinsightsService) {
    this.httpClientWithOutInterceptor = new HttpClient(handler);
  }

  public patch<T>(relativeUrl: string, data: any = null): Observable<T> {
    return this.http.patch<T>(`${this.baseUrl}${relativeUrl}`, data).pipe(
      catchError(this.handleError<T>('error'))
    );
  }

  public get<T>(relativeUrl: string, params: { [param: string]: string | string[] } = {}): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${relativeUrl}`, { params });
  }

  public post<T>(relativeUrl: string, data: any = null): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}${relativeUrl}`, data).pipe(
      catchError(this.handleError<T>('error'))
    );
  }

  public postWithoutInterceptor<T>(relativeUrl: string, data: any = null): Observable<T> {
    return this.httpClientWithOutInterceptor.post<T>(`${this.baseUrl}${relativeUrl}`, data).pipe(
      catchError(this.handleError<T>('error'))
    );
  }

  public gettWithoutInterceptor<T>(relativeUrl: string, params: { [param: string]: string | string[] } = {}): Observable<T> {
    return this.httpClientWithOutInterceptor.get<T>(`${this.baseUrl}${relativeUrl}`, { params }).pipe(
      catchError(this.handleError<T>('error'))
    );
  }

  public put<T>(relativeUrl: string, data: any = null): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}${relativeUrl}`, data).pipe(
      catchError(this.handleError<T>('error'))
    );
  }

  public delete<T>(relativeUrl: string): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}${relativeUrl}`).pipe(
      catchError(this.handleError<T>('error'))
    );
  }

  public uploadFile<T>(relativeUrl: string, data: any = null) {
    const headers = new HttpHeaders()
      .append('Content-Disposition', 'multipart/form-data');

    return this.http.post<T>(`${this.baseUrl}${relativeUrl}`, data, { headers }).pipe(
      catchError(this.handleError<T>('error'))
    );
  }

  public postWithErrors<T>(relativeUrl: string, data: any = null): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}${relativeUrl}`, data);
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      const errorMessage = `${operation} failed: ${error.message}`;
      console.error(errorMessage);
      this.appInsight.logException(error);
      // this.toast.show(errorMessage, { classname: 'bg-danger text-light', delay: 15000 });
      const resp = result || error.error;
      return throwError(resp as T);
    };
  }
}
