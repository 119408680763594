import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { AppGlobalsService } from '../services/app-globals.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../services/toast.service';
@Component({
  selector: 'app-ideas-list',
  templateUrl: './ideas-list.component.html',
  styleUrls: [ './ideas-list.component.css' ]
})
export class IdeasListComponent implements OnInit {
  talentTitularIdeasCounter = 0;
  fabLabTitularIdeasCounter = 0;
  talentJoinedIdeasCounter = 0;
  fabLabJoinedIdeasCounter = 0;
  talentIdeasList: any[] = [];
  fabLabIdeasList: any[] = [];
  talentTitularIdeasList: any[] = [];
  fabLabTitularIdeasList: any[] = [];
  talentJoinedIdeasList: any[] = [];
  fabLabJoinedIdeasList: any[] = [];
  loginDisplay = false;
  displayedColumns: string[] = [ 'claim', 'value' ];
  private readonly _destroying$ = new Subject<void>();
  isTalentTitularIdeasListLoading = true;
  isFabLabTitularIdeasListLoading = true;
  isTalentJoinedIdeasListLoading = true;
  isFabLabJoinedIdeasListLoading = true;
  constructor(private apiService: ApiService,
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public _appGlobal: AppGlobalsService
  ) { }

  ngOnInit() {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
      });
    
    this.apiService.listTalentIdeas().subscribe((result: any) => {
      this.talentIdeasList = result?.value;
      this.talentIdeasList.forEach(element => {
        if (element.ruoloUtente == "PROPONENTE") {
          this.talentTitularIdeasCounter++;
          element.counter = this.talentTitularIdeasCounter;
          this.talentTitularIdeasList.push(element);
        }
      });
      this.isTalentTitularIdeasListLoading = false;
    })
    this.apiService.listFabLabIdeas().subscribe((result: any) => {
      this.fabLabIdeasList = result?.value;
      this.fabLabIdeasList.forEach(element => {
        if (element.ruoloUtente == "PROPONENTE") {
          this.fabLabTitularIdeasCounter++;
          element.counter = this.fabLabTitularIdeasCounter;
          this.fabLabTitularIdeasList.push(element);
        }
      });
      this.isFabLabTitularIdeasListLoading = false;
    })
    this.apiService.listJoinedTalentIdeas().subscribe((result: any) => {
      this.talentJoinedIdeasList = result?.value;
      this.talentJoinedIdeasList.forEach(element => {
        this.talentJoinedIdeasCounter++;
        element.counter = this.talentJoinedIdeasCounter;
      });
      this.isTalentJoinedIdeasListLoading = false;
    })
    this.apiService.listJoinedFabLabIdeas().subscribe((result: any) => {
      this.fabLabJoinedIdeasList = result?.value;
      this.fabLabJoinedIdeasList.forEach(element => {
        this.fabLabJoinedIdeasCounter++;
        element.counter = this.fabLabJoinedIdeasCounter;
      });
      this.isFabLabJoinedIdeasListLoading = false;
    })
  }
  onDetailsClick(item: any, type: string) {
    this.router.navigate([ '/idea-details', type, item.codiceIdea ]);
  }
  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[ 0 ]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    this._appGlobal.claims = claims;
  }
}
