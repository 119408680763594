import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, InteractionStatus, PopupRequest, RedirectRequest, AuthenticationResult, AuthError } from '@azure/msal-browser';

import { b2cPolicies } from './auth-config';
import { AppGlobalsService } from './services/app-globals.service';
import { ActivatedRoute} from '@angular/router';
import { ApiService } from './services/api.service';
import { environment } from 'src/environments/environment';
import { SafeHtml } from './safe-html.pipe';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'KaiTech';
  isIframe = false;
  loginDisplay = false;
  isActive: boolean = false;
  inactiveAppMessage: string = "";
  private readonly _destroying$ = new Subject<void>();
  
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public _appGlobal: AppGlobalsService,
    private _appGlobals: AppGlobalsService,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isIframe = window !== window.parent && !window.opener;
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        this.setLoginDisplay();
        await this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
      });

      await this.getAppInfo();

  }
  async getAppInfo() {
    await this.apiService.getAppInfo().subscribe(
      (result: any) => {
        this.isActive = result.isActive;
        this.inactiveAppMessage = result.inactiveAppMessage;
      });
  }

  async getClaims(claims: any) {
    this._appGlobals.claims = claims;
    const isUserExsist = await this.apiService.userExsist().toPromise().catch(error=>{
      if (error.name === "InteractionRequiredAuthError") {
        // disalbe this for now it is effecting update profile workflow , we need to find other solution for it
        // this.logout();
      }
    });
    if (claims && isUserExsist && localStorage.getItem("edit-profile-click") === "true") {
      const result = this.authService.instance.getActiveAccount();
      const claims = result?.idTokenClaims as any;
      const user = {
        userId: claims.emails[ 0 ],
        nome: claims.given_name,
        cognome: claims.family_name,
        email: claims.emails[ 0 ],
        telefono: claims.extension_PhoneNumber,
        codiceFiscale: claims.extension_FiscalCode,
        sesso: claims.extension_Gender,
        dataNascita: claims.extension_BirthDate,
        luogoNascita: claims.extension_BirthPlace,
        occupazione: claims.extension_Occupation,
        tipologiaIstruzione: claims.extension_EducationType ?? "",
        dichiarazioneDiPresaVisione: claims.extension_DeclarationOfAcknowledgement.toString(),
        autorizzazioneMailingList: claims.extension_AuthorizationToMailingList.toString(),
        autorizzazioneInvioMateriale: claims.extension_AuthorizationToSendMaterial.toString(),
        autorizzazioneNetworking: claims.extension_AuthorizationToNetworking.toString(),
        autorizzazioneUfficioStampa: claims.extension_AuthorizationToPressOffice.toString(),
        autorizzazionePubblicazione: claims.extension_AuthorizationToPublication.toString()
      }
      await this.apiService.updateUser(user).toPromise();
      localStorage.setItem("edit-profile-click","false");
    }
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    this.authService.logout();
    localStorage.clear();
  }

  editProfile() {
    localStorage.setItem('edit-profile-click',"true");
    let editProfileFlowRequest = {
      scopes: [ "openid" ],
      authority: b2cPolicies.authorities.editProfile.authority
    };

    this.login(editProfileFlowRequest);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
