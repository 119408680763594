<div class="container-fluid">
  <div class="alert alert-danger d-block d-lg-none mt-3" style="font-size:24px; line-height:28px;">
      Per favore, ruota il tuo dispositivo mobile in orizzontale per visualizzare correttamente questa pagina
  </div>
  <div class="row">
    <div  class="col-12">
      <div *ngIf="isFabLabTitularIdeasListLoading || isTalentTitularIdeasListLoading || isFabLabJoinedIdeasListLoading || isTalentJoinedIdeasListLoading" >
        <br /><br /><br />
      </div>
      <app-spinner [isVisible]="isFabLabTitularIdeasListLoading || isTalentTitularIdeasListLoading || isFabLabJoinedIdeasListLoading || isTalentJoinedIdeasListLoading" > </app-spinner>
      
      <div *ngIf="!isFabLabTitularIdeasListLoading && !isTalentTitularIdeasListLoading && !isFabLabJoinedIdeasListLoading && !isTalentJoinedIdeasListLoading && talentTitularIdeasCounter == 0 && fabLabTitularIdeasCounter == 0 && talentJoinedIdeasCounter == 0 && fabLabJoinedIdeasCounter == 0">
        <br />
        <h2>Le tue idee</h2>
        <br />
        <div>
          Non sono al momento presenti idee.
        </div>
      </div>

      <div *ngIf="!isFabLabTitularIdeasListLoading && !isTalentTitularIdeasListLoading && (talentTitularIdeasCounter > 0 || fabLabTitularIdeasCounter > 0)">
        <br />
        <h2>Le tue idee</h2>
        <br />
        <div *ngIf="talentTitularIdeasCounter > 0">
          <h3>Talent</h3>
          <table class="table table-bordered table-hover" >
            <thead>
              <tr>
                <th scope="col" style="text-align: center;" >#</th>
                <th scope="col" style="text-align: center;" >Codice</th>
                <th scope="col" style="text-align: center;" >Tipologia</th>
                <th scope="col" style="text-align: center;" >Data inserimento</th>
                <th scope="col" style="text-align: center;" >Stato</th>
                <th scope="col" style="text-align: center;" >Titolo progetto</th>
                <th scope="col" style="text-align: center;" >Dettaglio</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of talentTitularIdeasList;let id = index">
                <th scope="row" style="text-align: center;">{{item?.counter}}</th>
                <td align="center">{{item?.codiceIdea}}</td>
                <td align="center">Talent</td>
                <td align="center">{{item?.created | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td align="center" >{{item?.status}}</td>
                <td align="center" >{{item?.titoloProgetto}}</td>
                <td align="center" >
                  <a class="btn btn-primary btn-sm" (click)="onDetailsClick(item,'Talent')">
                    DETTAGLIO
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="fabLabTitularIdeasCounter > 0">
          <h3>FabLab</h3>
          <table class="table table-bordered table-hover" >
            <thead>
              <tr>
                <th scope="col" style="text-align: center;" >#</th>
                <th scope="col" style="text-align: center;" >Codice</th>
                <th scope="col" style="text-align: center;" >Tipologia</th>
                <th scope="col" style="text-align: center;" >Data inserimento</th>
                <th scope="col" style="text-align: center;" >Stato</th>
                <th scope="col" style="text-align: center;" >Titolo progetto</th>
                <th scope="col" style="text-align: center;" >Dettaglio</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of fabLabTitularIdeasList;let id = index">
                <th scope="row" style="text-align: center;">{{item?.counter}}</th>
                <td align="center">{{item?.codiceIdea}}</td>
                <td align="center">FabLab</td>
                <td align="center">{{item?.created | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td align="center" >{{item?.status}}</td>
                <td align="center" >{{item?.titoloProgetto}}</td>
                <td align="center" >
                  <a class="btn btn-primary btn-sm" (click)="onDetailsClick(item, 'FabLab')">
                    DETTAGLIO
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
      <div *ngIf="!isFabLabJoinedIdeasListLoading && !isTalentJoinedIdeasListLoading && (talentJoinedIdeasCounter > 0 || fabLabJoinedIdeasCounter > 0)">
        <br />
        <h2>Le idee a cui hai aderito</h2>
        <br />
        <div *ngIf="talentJoinedIdeasCounter > 0">
          <h3>Talent</h3>
          <table class="table table-bordered table-hover" >
            <thead>
              <tr>
                <th scope="col" style="text-align: center;" >#</th>
                <th scope="col" style="text-align: center;" >Codice</th>
                <th scope="col" style="text-align: center;" >Tipologia</th>
                <th scope="col" style="text-align: center;" >Data inserimento</th>
                <th scope="col" style="text-align: center;" >Proponente</th>
                <th scope="col" style="text-align: center;" >Stato</th>
                <th scope="col" style="text-align: center;" >Titolo progetto</th>
                <th scope="col" style="text-align: center;" >Dettaglio</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of talentJoinedIdeasList;let id = index">
                <th scope="row" style="text-align: center;">{{item?.counter}}</th>
                <td align="center">{{item?.codiceIdea}}</td>
                <td align="center">Talent</td>
                <td align="center">{{item?.created | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td align="center" >{{item?.userId}}</td>
                <td align="center" >{{item?.status}}</td>
                <td align="center" >{{item?.titoloProgetto}}</td>
                <td align="center" >
                  <a class="btn btn-primary btn-sm" (click)="onDetailsClick(item,'Talent')">
                    DETTAGLIO
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="fabLabJoinedIdeasCounter > 0">
          <h3>FabLab</h3>
          <table class="table table-bordered table-hover" >
            <thead>
              <tr>
                <th scope="col" style="text-align: center;" >#</th>
                <th scope="col" style="text-align: center;" >Codice</th>
                <th scope="col" style="text-align: center;" >Tipologia</th>
                <th scope="col" style="text-align: center;" >Data inserimento</th>
                <th scope="col" style="text-align: center;" >Proponente</th>
                <th scope="col" style="text-align: center;" >Stato</th>
                <th scope="col" style="text-align: center;" >Titolo progetto</th>
                <th scope="col" style="text-align: center;" >Dettaglio</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of fabLabJoinedIdeasList;let id = index">
                <th scope="row" style="text-align: center;">{{item?.counter}}</th>
                <td align="center">{{item?.codiceIdea}}</td>
                <td align="center">FabLab</td>
                <td align="center">{{item?.created | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td align="center" >{{item?.userId}}</td>
                <td align="center" >{{item?.status}}</td>
                <td align="center" >{{item?.titoloProgetto}}</td>
                <td align="center" >
                  <a class="btn btn-primary btn-sm" (click)="onDetailsClick(item, 'FabLab')">
                    DETTAGLIO
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>
