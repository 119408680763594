import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { AppGlobalsService } from '../services/app-globals.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ToastService } from '../services/toast.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-talent',
  templateUrl: './talent.component.html',
  styleUrls: ['./talent.component.css'],
  animations: [
    trigger(
      'showhideAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('150ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('150ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class TalentComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  public newIdeaForm: FormGroup;
  loginDisplay = false;
  userEmail: any;
  locations = [];
  submitButtonText = "INVIA";
  file: File;
  display_blocco_team = false;
  display_blocco_team_impresa = false;
  showThankYou = false;
  ideaCode = "";

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public _appGlobals: AppGlobalsService,
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private toast: ToastService
  ) { 
    localStorage.setItem("requestroute","/");
  }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
        this._appGlobals.claims = this.authService.instance.getActiveAccount()?.idTokenClaims;
      });

    this.newIdeaForm = this.fb.group({
      Sede: [ null, [ Validators.required ] ],
      ComposizioneGruppoLavoro: [ null, [ Validators.required ] ],
      TipoStrutturaTeam: [ null, [ this.required_team ] ],
      DenominazioneImpresa: [ null, [ this.required_team_impresa, Validators.maxLength(254) ] ],
      CodiceFiscaleImpresa: [ null, [ this.required_team_impresa, Validators.maxLength(254) ] ],
      PartitaIvaImpresa: [ null, [ this.required_team_impresa, Validators.maxLength(254) ] ],
      EmailImpresa: [ null, [ this.required_team_impresa, Validators.maxLength(254) ] ],
      TitoloProgetto: [ null, [ Validators.required, Validators.maxLength(254) ] ],
      DescrizioneProgetto: [ null, [ Validators.required, Validators.maxLength(4000) ] ],
      Allegato: [ null ],
      //AutorizzazioneDirittiNda: [ false, [ Validators.requiredTrue ] ],
      AutorizzazioneDirittiNda: [ false ],
    });

    this.newIdeaForm.get("ComposizioneGruppoLavoro")?.valueChanges
    .subscribe(value => {
      this.newIdeaForm.get("TipoStrutturaTeam")?.updateValueAndValidity();
    });

    this.newIdeaForm.get("TipoStrutturaTeam")?.valueChanges
    .subscribe(value => {
      this.newIdeaForm.get("DenominazioneImpresa")?.updateValueAndValidity();
      this.newIdeaForm.get("CodiceFiscaleImpresa")?.updateValueAndValidity();
      this.newIdeaForm.get("PartitaIvaImpresa")?.updateValueAndValidity();
      this.newIdeaForm.get("EmailImpresa")?.updateValueAndValidity();
    });

    this.getLocations();
  }

  getLocations() {
    this.apiService.getLocations("Talent").subscribe((result: any) => {
      this.locations = result.value;
    });
  }

  required_team(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent?.get("ComposizioneGruppoLavoro")?.value == "Team") {
      return Validators.required(formControl);
    }
    return null;
  }

  required_team_impresa(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent?.get("TipoStrutturaTeam")?.value == "Impresa") {
      return Validators.required(formControl);
    }
    return null;
  }

  toggle_blocco_team() {
    if(this.newIdeaForm.get("ComposizioneGruppoLavoro")?.value == "Team") {
      this.display_blocco_team = true;
    }
    else {
      this.display_blocco_team = false;
      this.newIdeaForm.get("TipoStrutturaTeam")?.setValue(null);
      this.newIdeaForm.get("DenominazioneImpresa")?.setValue(null);
      this.newIdeaForm.get("CodiceFiscaleImpresa")?.setValue(null);
      this.newIdeaForm.get("PartitaIvaImpresa")?.setValue(null);
      this.newIdeaForm.get("EmailImpresa")?.setValue(null);
    }
  }

  toggle_blocco_team_impresa() {
    if(this.newIdeaForm.get("TipoStrutturaTeam")?.value == "Impresa") {
      this.display_blocco_team_impresa = true;
    }
    else {
      this.display_blocco_team_impresa = false;
      this.newIdeaForm.get("DenominazioneImpresa")?.setValue(null);
      this.newIdeaForm.get("CodiceFiscaleImpresa")?.setValue(null);
      this.newIdeaForm.get("PartitaIvaImpresa")?.setValue(null);
      this.newIdeaForm.get("EmailImpresa")?.setValue(null);
    }
  }

  // On file Select
  onChange(event:any) {
    if (
      event.target.files[0].type === 'application/pdf'
      || event.target.files[0].type === 'application/zip'
      ) {
    this.file = event.target.files[0];
    }else{
      this.newIdeaForm.controls['Allegato'].patchValue(null);
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[ 0 ]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    this._appGlobals.claims = claims;
  }

  async onSubmit() {
    if (this.newIdeaForm.valid) {
      this.submitButtonText = 'Attendere prego...';
      const claims = this._appGlobals.claims;
      const user = {
        userId: claims.emails[ 0 ],
        nome: claims.given_name,
        cognome: claims.family_name,
        email: claims.emails[ 0 ],
        telefono: claims.extension_PhoneNumber,
        codiceFiscale: claims.extension_FiscalCode,
        sesso: claims.extension_Gender,
        dataNascita: claims.extension_BirthDate,
        luogoNascita: claims.extension_BirthPlace,
        occupazione: claims.extension_Occupation,
        tipologiaIstruzione: claims.extension_EducationType ?? "",
        dichiarazioneDiPresaVisione: claims.extension_DeclarationOfAcknowledgement.toString(),
        autorizzazioneMailingList: claims.extension_AuthorizationToMailingList.toString(),
        autorizzazioneInvioMateriale: claims.extension_AuthorizationToSendMaterial.toString(),
        autorizzazioneNetworking: claims.extension_AuthorizationToNetworking.toString(),
        autorizzazioneUfficioStampa: claims.extension_AuthorizationToPressOffice.toString(),
        autorizzazionePubblicazione: claims.extension_AuthorizationToPublication.toString()
      }
      this.apiService.addUser(user).subscribe(res => {
        console.log('user added to share point');
      });
      const data = this.newIdeaForm.value;
      const formData = new FormData();
      
      var fileUrl = "";
      if (this.file) {
        formData.append('file', this.file, this.file.name);
        formData.append('mimeType', this.file.type);
        formData.append('listName', 'Talent');
        const fileResponse:any = await this.apiService.uploadFile(formData).toPromise();
        fileUrl = fileResponse.ServerRelativeUrl;
      }

      const idea = {
        UserId: "",
        Sede: data.Sede,
        ComposizioneGruppoLavoro: data.ComposizioneGruppoLavoro,
        TipoStrutturaTeam: data.TipoStrutturaTeam ?? "",
        DenominazioneImpresa: data.DenominazioneImpresa ?? "",
        CodiceFiscaleImpresa: data.CodiceFiscaleImpresa ?? "",
        PartitaIvaImpresa: data.PartitaIvaImpresa ?? "",
        EmailImpresa: data.EmailImpresa ?? "",
        TitoloProgetto: data.TitoloProgetto,
        DescrizioneProgetto: data.DescrizioneProgetto,
        Allegato: fileUrl,
        AutorizzazioneDirittiNda: data.AutorizzazioneDirittiNda,
        CodiceIdea: "",
        RuoloUtente: "PROPONENTE",
        Status: "In attesa di valutazione"
      };
      console.table(idea);
      this.apiService.addTalentIdea(idea).subscribe((res:any) => {
        // this.router.navigate(["thank-you"]);
        this.ideaCode = res?.codiceIdea;
        this.showThankYou = true;
      },(error =>{
        this.submitButtonText = 'INVIA';
        console.error(error);
        // this.toast.show(error, { classname: 'bg-danger text-light', delay: 15000 });
      }));
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
