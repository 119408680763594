<div class="container d-none d-md-block">
  <div class="row menu-whiterow">
    <div class="col">
      <div class="float-left">
        <div *ngIf="loginDisplay">Ciao, {{ _appGlobal?.claims?.given_name }} {{ _appGlobal?.claims?.family_name }}</div>
      </div>
      <div class="float-right" style="color:rgb(0, 166, 81);">
        <a href="https://lazioinnova.it/contatti/" target="_blank" style="color:rgb(0, 166, 81);">Orientamento e contatti</a> | <a href="https://lazioinnova.it/note-legali-informativa-privacy/" target="_blank">Note legali e Privacy</a>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row menu-logorow d-none d-md-block">
    <div class="col">
      <div class="container">
        <a href="https://www.lazioinnova.it" target="_blank">
          <img
            src="./assets/images/top-bar.png"
            alt="Regione Lazio - Lazio Innova"
          />
        </a>
      </div>
    </div>
  </div>
  <div class="row menu-logorow-mobile d-md-none">
    <div class="container">
      <a href="https://www.lazioinnova.it" target="_blank">
        <img
          src="./assets/images/top-bar-mobile.png"
          alt="Regione Lazio - Lazio Innova"
        />
      </a>
      <div class="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarContents" aria-controls="#navbarContents">
      </div>
    </div>
  </div>
</div>

<div class="container">
  <nav class="navbar navbar-expand-md">
    <div class="collapse navbar-collapse" id="navbarContents">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <span class="nav-link" style="color:rgb(153, 153, 153) !important;">FabLab & Talent</span>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="loginDisplay" href="/">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="loginDisplay && isActive" [routerLink]="['/talent']">Talent</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="loginDisplay && isActive" [routerLink]="['/fablab']">FabLab</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="loginDisplay && isActive" [routerLink]="['/join']"> Aderisci ad una idea</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="loginDisplay && isActive" [routerLink]="['/ideas']"> Le tue idee</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="loginDisplay && isActive" (click)="editProfile()" style="cursor:pointer;">Il tuo profilo</a>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" *ngIf="!loginDisplay" (click)="login()" style="cursor:pointer; color:rgb(0, 166, 81) !important;">Accedi</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="loginDisplay" (click)="logout()" style="cursor:pointer; color:rgb(0, 166, 81) !important;">Esci</a>
        </li>
      </ul>
    </div>
  </nav>
  
</div>

<div class="container" *ngIf="isActive">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
<div class="container" *ngIf="!isActive">
  <div class="row" style="padding: 1rem;">
    <div class="col">
      <div [innerHTML]="inactiveAppMessage | safeHtml"></div>
    </div>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div class="container-fluid footer-container">
  <div class="container">
    <div class="row footer-logorow">
      <div class="col-sm-6">
        <a href="https://www.lazioinnova.it" target="_blank">
          <img
            src="./assets/images/logo_regionelazio_lazioinnova.png"
            alt="Regione Lazio - Lazio Innova"
          />
        </a>
      </div>
      <div class="col-sm-6">
        <div class="footer-socials">
          Social link
          <ul>
            <li><a href="https://www.facebook.com/lazioinnova" target="_blank"><i class="fab fa-facebook"></i>Facebook </a></li>
            <li><a href="https://x.com/LazioInnova" target="_blank"><i class="fab fa-x-twitter"></i>X </a></li>
            <li><a href="https://it.linkedin.com/company/lazio-innova-spa" target="_blank"><i class="fab fa-linkedin"></i>LinkedIn </a></li>
            <li><a href="https://www.instagram.com/lazioinnova/" target="_blank"><i class="fab fa-instagram"></i>Instagram </a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row footer-copyrightrow">
      <div class="col">
        © Lazio Innova - Via Marco Aurelio 26 A - 00184 Roma - Partita Iva e Codice fiscale 05950941004
      </div>
    </div>
  </div>
</div>
