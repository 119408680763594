import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Calendar,
  CalendarOptions,
  FullCalendarComponent,
  getEventClassNames,
} from '@fullcalendar/angular';
import * as moment from 'moment';
import 'moment/locale/it';
import { ApiService } from '../services/api.service';
@Component({
  selector: 'app-availability-calendar',
  templateUrl: './availability-calendar.component.html',
  styleUrls: ['./availability-calendar.component.css'],
})
export class AvailabilityCalendarComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarApi: Calendar;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    themeSystem: 'bootstrap',
    headerToolbar: false,
    locale: 'it',
    firstDay: 1,
    eventClick: this.handleDateClick.bind(this),
    //dateClick: this.handleDateClick.bind(this)
  };
  Sede: string;
  Categoria: string = 'All';
  idea: any = {};
  type: string;
  code: string;
  locations = [];
  screen: string;
  singularName: string;
  pluralName: string;
  calendarMonth: string = moment().format('MMMM');
  calendarYear: string = moment().format('yyyy');
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {
    moment.locale('it');
    this.idea = JSON.parse(localStorage['ideaDetails']);
    this.Sede = this.idea.sede;
    this.code = this.route.snapshot.params.code;
    this.type = this.route.snapshot.params.type;
    this.screen = this.route.snapshot.params.screen.toLowerCase();
    this.Categoria = this.type.toLowerCase() == 'fablab' ? 'FabLab' : 'Talent';
    if (this.screen == "operator") {
      this.singularName = "un colloquio";
      this.pluralName = "colloqui";
    }
    else if (this.screen == "spazi") {
      this.singularName = "una postazione";
      this.pluralName = "postazioni";
    }
    else if (this.screen == "macchine") {
      this.singularName = "un dispositivo";
      this.pluralName = "dispositivi";
    }
    this.getLocations();
  }
  getLocations() {
    this.apiService.getLocations(this.type).subscribe((result: any) => {
      this.locations = result.value;
    });
  }
  getControllerName(): string {
    let conrollers: any = {
      macchine: 'SlotMacchine',
      operator: 'SlotColloqui',
      spazi: 'SlotSpazi',
    };
    return conrollers[this.screen];
  }
  getAvailableSlots(dt?: Date) {
    var events: any = [];
    if (!dt) {
      dt = this.calendarApi.getDate();
    }
    this.calendarMonth = moment(dt).format('MMMM');
    this.calendarYear = moment(dt).format('yyyy');
    this.apiService
      .getAvailability(
        this.apiService.getControllerName(this.screen),
        this.Sede,
        this.Categoria,
        dt.getFullYear(),
        dt.getMonth() + 1
      )
      .subscribe((result: any) => {
        var dates = result.daysList;
        console.log(dates);
        for (var i = 0; i < dates.length; i++) {
          if (dates[i].availableSlots > 0) {
            events.push({
              date: dates[i].date.split('T')[0],
              title: dates[i].availableSlots,
              className: 'custom-event',
            });
          }
        }
        console.log(events);

        this.calendarOptions.events = events;
      });
  }
  gotoIdeaDetails() {
    this.router.navigate(['/idea-details', this.type, this.code]);
  }
  monthChanged(action: string): void {
    if (action == 'Next') this.calendarApi.next();
    else this.calendarApi.prev();
    this.getAvailableSlots();
  }
  ngOnInit(): void {}
  handleDateClick(arg: any) {
    console.log(arg);
    this.router.navigate([
      '/availability-table',
      this.screen,
      this.type,
      this.code,
      this.Sede,
      arg.event.startStr,
    ]);
  }
  ngAfterViewInit() {
    this.calendarApi = this.calendarComponent.getApi();
    this.getAvailableSlots();
  }
}
