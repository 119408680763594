import { Injectable } from "@angular/core";
import { Router, ResolveEnd, ActivatedRouteSnapshot } from "@angular/router";
import { filter } from "rxjs/operators";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

@Injectable({
  providedIn: 'root'
})
export class AppinsightsService {

    appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: "08962a9b-4be7-47f8-851a-9e0d766fc5ae",
                loggingLevelConsole: 0,
            }
        });

        this.appInsights.loadAppInsights();
    }
    logEvent(name: string, properties?: { [ key: string ]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [ key: string ]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [ key: string ]: any }) {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}
