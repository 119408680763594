import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = environment.apiUrl;
  constructor(private restService: RestService) {}
  private conrollers: any = {
    macchine: 'SlotMacchine',
    operator: 'SlotColloqui',
    spazi: 'SlotSpazi',
  };
  getControllerName(screen: string): string {
    return this.conrollers[screen];
  }
  addTalentIdea(idea: any) {
    return this.restService.post(`${this.baseUrl}/Talent`, idea);
  }
  getTalentIdea(id: string) {
    return this.restService.get(`${this.baseUrl}/Talent/${id}`);
  }
  getTalentIdeaByUserIDAndCode(userid: string, code: string) {
    return this.restService.get(`${this.baseUrl}/Talent/GetByUserIDAndCode/${userid}/${code}`);
  }
  getAppInfo(): any {
    return this.restService.gettWithoutInterceptor(`${this.baseUrl}/GetAppInfo`);
  }
  listTalentIdeas(): any {
    return this.restService.get(`${this.baseUrl}/Talent`);
  }
  listJoinedTalentIdeas(): any {
    return this.restService.get(`${this.baseUrl}/Talent/Joined?type=Joined`);
  }

  addFabLabIdea(idea: any) {
    return this.restService.post(`${this.baseUrl}/FabLab`, idea);
  }
  getFabLabIdea(id: string) {
    return this.restService.get(`${this.baseUrl}/FabLab/${id}`);
  }
  getFabLabIdeaByUserIDAndCode(userid: string, code: string) {
    return this.restService.get(`${this.baseUrl}/FabLab/GetByUserIDAndCode/${userid}/${code}`);
  }
  listFabLabIdeas(): any {
    return this.restService.get(`${this.baseUrl}/FabLab`);
  }
  listJoinedFabLabIdeas(): any {
    return this.restService.get(`${this.baseUrl}/FabLab/Joined?type=Joined`);
  }

  uploadFile(data: any) {
    return this.restService.uploadFile(`${this.baseUrl}/FileLibrary`, data);
  }
  addUser(user: any) {
    return this.restService.post(`${this.baseUrl}/User`, user);
  }
  updateUser(user: any) {
    return this.restService.put(`${this.baseUrl}/User`, user);
  }
  userExsist() {
    return this.restService.get(`${this.baseUrl}/User/exsist`);
  }
  getAvailability(
    controller: string,
    location: string,
    category: string,
    year: number,
    month: number
  ) {
    let url = `${this.baseUrl}/${controller}/AvailableSlotCalendar/${location}/${year}/${month}`;
    if (controller.toLowerCase() == 'slotcolloqui') {
      url = `${this.baseUrl}/${controller}/AvailableSlotCalendar/${category}/${location}/${year}/${month}`;
    }
    return this.restService.get(url);
  }
  getAvailabilityListing(
    controller: string,
    location: string,
    category: string,
    dateStr: string
  ) {
    let url = `${this.baseUrl}/${controller}/AvailableSlotList`;
    if (controller == this.conrollers.operator) {
      url += `/${category}`;
    }
    url += `/${location}/${dateStr}`;
    return this.restService.get(url);
  }
  getLocations(type: string) {
    return this.restService.get(`${this.baseUrl}/Sedi/List/${type}`);
  }
  updateSlots(
    controller: string,
    id: number,
    status: string,
    ideaCode?: string,
    executionMode?: string,
    teamsUrl?: string,
    proposerNotes?: string,
  ) {
    return this.restService.post(`${this.baseUrl}/${controller}/UpdateSlot`, {
      SlotID: id,
      Status: status,
      CodiceIdea: ideaCode ? ideaCode : '',
      ModalitaEsecuzioneSelezioneProponente: executionMode ? executionMode : '',
      UrlTeams: teamsUrl ? teamsUrl : '',
      NoteProponente: proposerNotes ? proposerNotes : '', 
    });
  }
  checkReservation(controller: string, ideaCode: string) {
    return this.restService.get(
      `${this.baseUrl}/${controller}/ReservedSlot/${ideaCode}`
    );
  }
  getSlotsHistory(controller: string, ideaCode: string) {
    return this.restService.get(
      `${this.baseUrl}/${controller}/IdeaSlotList/${ideaCode}`
    );
  }
}
